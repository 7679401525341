import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Indochino Ecommerce Website",
  "videoSourceURL": "./images/Indochino basket.mp4",
  "featuredImage": "./images/indochino-plp-upsell.png",
  "coverAlt": "Indochino Ecommerce Website",
  "description": "Front End Development, UI refactoring, UX, performance optimization, launching new products, improving accessibility, etc. for Indochino's ecommerce website.",
  "date": "2021-10-19",
  "dateModified": "2021-10-19",
  "category": "portfolio",
  "projectLink": "http://www.indochino.com",
  "tags": ["Front End Development", "UI", "UX"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Building new UI components for new products & features (e.g. fully customizable callouts on product listing pages), improving look and feel of Product details pages, refactoring and slimming down existing codebase, improving accessibility on website (ongoing), adding React error boundaries and lazy loading to website, improving page speed and performance optimizations (Lighthouse improvement from high 20’s to mid 50’s), optimizing webpack, debugging performance on multiple devices.`}</p>
    <p>{`Converting ecommerce website from adaptive to fully responsive, A/B testing and launching new stand-alone basket page, optimizing checkout and order confirmation pages, creating landing pages for marketing team, etc.`}</p>
    <p>{`React, Gatsby, SASS, framer motion, facebook pixel, webpack, bootstrap, purgecss, etc. Optimizing website performance using webpack, best practices for front end development, lazy-loading, etc.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1440px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/19feb0f3c07abb6b08bcf2847f8dd581/3c7f1/indochino_plp_callout.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "55.55555555555556%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACt0lEQVQoz0WTW08TURSF+z989Rf4pg8m6pOJL75pjJp6qRrBGuOVGG+JRkHkpmIIKipKtIIiUUBJKaJGRBQbpNIG0RYK2nbaKdPpzJyZ+cwcbzvZOWcn+6yz18ravnQ6TXoujRA2XliWheu4CCHkaQuBANT2O4i5WRzAFha2bWOa5r9UFAXDMPB5IMXFIrPpFIlEnPkf88wupJmbn2N6ZppUOkk2l8FyQSvplA1TpmUJTMsDFehlg3LZANfFZ1km4ZcRQt1dbA/4aWyq4cT+HZw6cYT1a1dzru4k/eGnWDZ8Hn3L5KcoWdVA08oUyzA+HCEx+QXN9EZz8dm2IPI6TF+kj4oDQVoazlJbsYVgwM/mNcs5fHQvr0eHEA68Cg9ysbqOr6ksLvA5NsNOfyW3bj+QUriOg8+0DHrDPbR1tdFYFSBUf4wLFRtZtXQJe7ZtZt3KFZw5eljq2//oMYEtO3gfTch6YiLO1g1+LjXekLXjAQpbMDwyxNOBR3SeD9DRUEX7hSCBtcu41FhNpX8Th3bvkg+evximMlhFLJ7EcSE1m2Gbfx+hu6H/E3qUX448ZOBFK/daj3OzuYrmi0Hqzu3mytXT1NQcpPPhDUlxIPKO6vpWFnIaZdMmNvWNq9duE41+QTfsPxo6Nu/HXvE20ks4dJ3B7nae3LpMqKWWvmfd3O+4S29PDyXTZXB4lNr6Fr6nMhSKOtHYNE3NbYyOT6GoumTh83grSoFMTiFXKGIKl7/hOP/vubzG+IcovV2P6Z+IM5NRSKYWuNfRSXhohDfvPvwGdF1XmjKbzaLk85iGgSeDEJZs8AxumSaapknze5/8LJVQdR2jbEgpCqr6bzEkYHFRJ6eo0mfJ5DxaySBfWCQ+PsbPrIK6WJI+nJ6K8zURRzcsaei8ohL7OIYhoLa+ifsPQvwCQNHzDkf7GE8AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Indochino listing page",
              "title": "Indochino listing page",
              "src": "/static/19feb0f3c07abb6b08bcf2847f8dd581/89066/indochino_plp_callout.png",
              "srcSet": ["/static/19feb0f3c07abb6b08bcf2847f8dd581/53918/indochino_plp_callout.png 360w", "/static/19feb0f3c07abb6b08bcf2847f8dd581/242a6/indochino_plp_callout.png 720w", "/static/19feb0f3c07abb6b08bcf2847f8dd581/89066/indochino_plp_callout.png 1440w", "/static/19feb0f3c07abb6b08bcf2847f8dd581/3c7f1/indochino_plp_callout.png 1904w"],
              "sizes": "(max-width: 1440px) 100vw, 1440px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Indochino listing page`}</figcaption>{`
  `}</figure>{`
`}<figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1440px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/1525be8343ac40374e848b33429ad121/ac7a9/indochino_pdp_new.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "48.888888888888886%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB30lEQVQoz32Tz2sTQRTHc9ej0oPiRY+CJ/FP0JsgehMUxYsgeAmehByKl4CgFyUknsVDQVOhZQvSmpZeU2xaETRNSJof+3uT7O7M7H5kNiQYWvzC480MzHe+3/fe5Hq9HkLESClRSpEkCYnOgL23R98w0EiVmmZASckMei2FIApDoigixz8wTRP9gG3bhGE4vQAMbBt7PGZ36xuddpOxEPxuHrHfOJw+kqZzjpzezA7arRb1ep12u52pJU2J4pjjoYkTxmytfOJjqUTXdPix32B9bSO7p13NeBYItW3HcbBMM5OvIaTEcjxcz+dFvsClC9fI5wvYtsufZmuu8FTCIAjwPC+L8WQyJ3RcH9cf8/j+A25cvc7DR884OPxJ7fv2gsITlqvVKsVikXK5nIVWqVSC6wUEowl3b99j6dxlvqyuc9Rqs1pd+z/hYDCg0+nQ7/fpdrskicpqabs+qVQ8f/KUM2cvYmxsIpTA8/0FyycI4zhCCKF7keVpXRVDy8nW795/YOn8FRoHv5iEEX4wmnd3NnYLhNribFymxCmxlBxbFiMRs/L5Kzdv3eHN2xLuaITlenPLp46NbsZwOMSyrIUum67HREo2a7ssv3pN4eUyte0dQimzT6BRqVQwDIO/M3nezEBWLBkAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Indochino product page",
              "title": "Indochino product page",
              "src": "/static/1525be8343ac40374e848b33429ad121/89066/indochino_pdp_new.png",
              "srcSet": ["/static/1525be8343ac40374e848b33429ad121/53918/indochino_pdp_new.png 360w", "/static/1525be8343ac40374e848b33429ad121/242a6/indochino_pdp_new.png 720w", "/static/1525be8343ac40374e848b33429ad121/89066/indochino_pdp_new.png 1440w", "/static/1525be8343ac40374e848b33429ad121/ac7a9/indochino_pdp_new.png 1920w"],
              "sizes": "(max-width: 1440px) 100vw, 1440px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Indochino product page`}</figcaption>{`
  `}</figure>{`
`}<figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1440px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/6b1a1ec5e8fb147724d3eb9a3789924a/3c7f1/indochino-basket.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "55.55555555555556%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVQoz11STW/TQBDNry2H/IxI9MChxaFV89XGoYcKhDj0hIRAoF5ACOTQQhuUL3udko9NXGe9dljbD+04DglPet7Z0du3M+MttNttWJYF23bgOA5s296QMYaR68IdT9CtVMEsC879PYaDAYZDm/TdXg/9fh/X1ze463RQOD4uwzAOcHRkoF6v4LRRg9k8Rb1WoX2tegLTPEPr2VNcPG/i/NyknD5XLh+i1WygZZ6hUa/S2QIIKZbLB0gpsIsUSaJo/T8PJEjTBH6kKM5RSNMUcRxjPB6Dc07xSiliHGdCrUmShKhzSZqSpUoSfHcX8Hyx0ZChhs0Yvn5rQ4gAc9fFgjkQIhNuU184nXNM+AwilPijVlCx2lxc0K4ab95+xJODCsUqipAqhSiKyCAX56vneVAqM4lWK4hA7Las8fmLhXfvrygOpCShlJKYX5ob6uqWIjPhizl+Tyf/KswDKQNEmlGEQAiEUrejqJLtljX2H++jWCyiVCphb+8RDMPYMlyLfnZ+4cPVJ4ggALdtPMxmUHG802o++NHIxaDfg8scMGZjNp3QaDQ3Lbd/3OLV68vs8LrFMAx3ZriWIhA+lr4H3/fgLTjtNzPUH/0M2GiEu9sbKLX7E7ary3OcTzAcdMFnY2IYChwaZVy8eIm/AbQxJkYg394AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Indochino basket page",
              "title": "Indochino basket page",
              "src": "/static/6b1a1ec5e8fb147724d3eb9a3789924a/89066/indochino-basket.png",
              "srcSet": ["/static/6b1a1ec5e8fb147724d3eb9a3789924a/53918/indochino-basket.png 360w", "/static/6b1a1ec5e8fb147724d3eb9a3789924a/242a6/indochino-basket.png 720w", "/static/6b1a1ec5e8fb147724d3eb9a3789924a/89066/indochino-basket.png 1440w", "/static/6b1a1ec5e8fb147724d3eb9a3789924a/3c7f1/indochino-basket.png 1904w"],
              "sizes": "(max-width: 1440px) 100vw, 1440px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Indochino basket page`}</figcaption>{`
  `}</figure>{`
`}<figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1440px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/9e0def6d6fcf7e1d2bbe5e869ffe6d5d/4f64b/indochino-plp-upsell.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAADAElEQVQ4y1WSy08bVxyFvWwbktRsIlWgEAKOjQ3jsWcwHnseJihSaYAiqjb/EQtW8ZJsWqkrTIEoUiUDC6S0hdgVpgUFVymtUGwIbu3Gz0G8vmpuC22udPS7c6X76cw519Xtk+j2SvT6Q0KeQIiRj8cYn/qCscnPGZ96xMOHk4w++ISxqUeMTnzG5MSnTI0+wH/Py90+mR6/TE+fzF1fEJe70zkMio87Xon2Th/ptedU7Savj97we7HA69IRtWaLcrlCtVbn9PSU7PoP3Oro5eZHXtwdjnxCLsdRQFbpl1X8QYV7/WG+38iQSn1DoVCkUqlg2zYnJydif7m2d3bwSYP0+mVxx+F4AmFclmVimiYJyxLz/nCCzdwWX371NYXiwRWgXC4zPT1NMplkZmaG2dlZJsbHMA2D4eEECcskkbBw6bqBrusYpokWi4n9z9s7HL45ol6vXwFbrRZra2uk02lWV1dZXlnh/sgIsXhM3DX/lcuyLAzDIKYb+Pt8RCIRMpkMzWaDUqlEs9nk7OxM6P9rb28PVVUISgPEdB3dMHBYrkvyUFSjra2drm4PW7mccOQAW62mKMHJ0D4+plarcn5xwW4+T38gwI2bbsKKKmDCoQBaFkNDUd57vw2P18/GixcUDw85Kv1Bs3VMtVql3mhQrdaoN5pcnEM+/4sAXrt2ncFI5F2glUgghxQ+aHML8PrGBsWDA/5626Bln7OcXiabzfLs2xW2tnfZ3y+S/XGTASnI9Rtu4vG4gP33y5ZFSFH5sP2WADoOnSfyZ+Ut1ZrNy908r37dYz2zyavf9tn86SXPv1tHHYzQ0XlbTIfhvBhRihOobph0dd1BCSvkclsiePvYFvOyENtuXZVSKBSQ5TA9vR6RoWla77Ycj8UYUlVi8TiPk0mePX3K/HyKpaUl5lMpFhYWmJubE3NxcZHZJ0+IRjW0aBRF04jqOsOJxD/AuG6gRDQGo3GhAVnBF5Dw90tIgQCSHCYcDhMKhYRUVUVRFJGdpmnCmXMuSRJ/A3/JfbzAJrAMAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Indochino upsell",
              "title": "Indochino upsell",
              "src": "/static/9e0def6d6fcf7e1d2bbe5e869ffe6d5d/89066/indochino-plp-upsell.png",
              "srcSet": ["/static/9e0def6d6fcf7e1d2bbe5e869ffe6d5d/53918/indochino-plp-upsell.png 360w", "/static/9e0def6d6fcf7e1d2bbe5e869ffe6d5d/242a6/indochino-plp-upsell.png 720w", "/static/9e0def6d6fcf7e1d2bbe5e869ffe6d5d/89066/indochino-plp-upsell.png 1440w", "/static/9e0def6d6fcf7e1d2bbe5e869ffe6d5d/36aa2/indochino-plp-upsell.png 2160w", "/static/9e0def6d6fcf7e1d2bbe5e869ffe6d5d/f9c26/indochino-plp-upsell.png 2880w", "/static/9e0def6d6fcf7e1d2bbe5e869ffe6d5d/4f64b/indochino-plp-upsell.png 4845w"],
              "sizes": "(max-width: 1440px) 100vw, 1440px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Indochino upsell`}</figcaption>{`
  `}</figure></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      